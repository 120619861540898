import { GetStartedLink, SingleValueProp } from '@rategravity/marketing-components';
import { buildMediaQueries } from '@rategravity/marketing-components';
import {
  colors,
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpText
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import styled from 'styled-components';
import { getStartedHeroValueProps } from '../../../data/content/get-started/hero-value-props';
import { metadata } from '../../../data/metadata/images';
import { CTAButton } from '../../find-your-best-mortgage-mktg02/hero';
import { Header } from '../../typography/icon-landing';

const HeroContentWrapperStyle = createOwnUpStyle({
  textAlign: 'center',
  maxWidth: '1025px',
  margin: '0 128px 40px',
  alignSelf: 'center',
  variants: {
    medium: {
      margin: '60px 128px'
    },
    smallAndDown: {
      margin: '60px 10%'
    }
  }
});

export const HeroContentWrapper = createOwnUpComponent('div', HeroContentWrapperStyle);

const SubHeader = styled(OwnUpText)`
  font-size: 20px;
  line-height: 28px;
  ${buildMediaQueries('mediumAndDown', {
    fontSize: '16px'
  })}
`;

const HeroContent = () => (
  <HeroContentWrapper>
    <Header>A great mortgage rate is a few clicks away.</Header>
    <SubHeader>
      Whatever your home financing situation, you deserve a great rate on your mortgage. We
      benchmark offers across thousands of lenders, so you don&apos;t have to hope you&apos;re
      getting a great deal &mdash; you&apos;ll know.
    </SubHeader>
  </HeroContentWrapper>
);

const ValuePropWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  background-color: ${colors.WHITE};
  width: 80%;
  padding: 42px 24px;
  & .catchphrase {
    margin-bottom: 16px;
    @media screen and (min-width: 1151px) {
      max-width: 200px;
    }
    & > span {
      font-size: 24px;
    }
  }
  & .primary-text {
    margin-bottom: 24px;
    & > span {
      font-size: 17px;
      line-height: 25px;
    }
  }
  ${buildMediaQueries('medium', {
    padding: '32px 24px'
  })}
  ${buildMediaQueries('smallAndDown', {
    padding: '42px 20px'
  })}
`;

const ValuePropsWrapperStyle = createOwnUpStyle({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  justifyItems: 'center',
  columnGap: '24px',
  rowGap: '24px',
  // Remove icon container, which doesn't have a class on it:
  '& section > :first-child': {
    display: 'none'
  },
  variants: {
    large: {
      '@media screen and (max-width: 1150px)': {
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '1fr 1fr',
        columnGap: '0',
        margin: 'auto'
      }
    },
    medium: {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr 1fr',
      columnGap: '0',
      margin: 'auto',
      '@media screen and (max-width: 800px)': {
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr 1fr 1fr 1fr',
        width: '60%'
      }
    },
    smallAndDown: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr 1fr 1fr 1fr',
      margin: 'auto'
    }
  }
});

const ValuePropsWrapper = createOwnUpComponent('div', ValuePropsWrapperStyle);

const CardsCTAButton = styled(CTAButton)`
  width: 220px;
  margin-top: 0;
`;

const ValueProps = () => {
  return (
    <ValuePropsWrapper>
      {getStartedHeroValueProps.map(({ url, cta, data }, i) => (
        <ValuePropWrapper key={i}>
          <SingleValueProp Image={null} data={data} metadata={metadata} decorativeIcon={true} />
          <GetStartedLink
            www={''}
            url={`${process.env.GATSBY_ONBOARDING}/${url}`}
            label="Apply Now"
          >
            <CardsCTAButton>{cta}</CardsCTAButton>
          </GetStartedLink>
        </ValuePropWrapper>
      ))}
    </ValuePropsWrapper>
  );
};

const HeroWrapperStyle = createOwnUpStyle({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '100px'
});

const HeroWrapper = createOwnUpComponent('div', HeroWrapperStyle);

const ContentWrapperStyle = createOwnUpStyle({
  display: 'flex',
  margin: 'auto',
  justifyContent: 'center',
  maxWidth: '900px'
});

const ContentWrapper = createOwnUpComponent('div', ContentWrapperStyle);

export const GetStartedHero = () => {
  return (
    <HeroWrapper>
      <HeroContent />
      <ContentWrapper>
        <ValueProps />
      </ContentWrapper>
    </HeroWrapper>
  );
};
